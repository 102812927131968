@use './styles/_mixins.scss' as *;
.slug {
  // &_nav {
  //   display: none;
  //   @include breakpoint(huge) {
  //     display: block;
  //     position: sticky;
  //     top: -1px;
  //     margin-top: -100px;
  //     padding-left: 35px;
  //     margin-bottom: 100px;
  //     color: white;
  //     z-index: 3;
  //     text-align: left;

  //     & > div {
  //       margin: unset;
  //     }
  //   }
  // }

  &_breadcrumbs {
    @include breakpoint(large) {
      padding-left: 54px;
    }
  }

  &_description {
    position: relative;
    margin-top: rem(48);
    margin-bottom: rem(40);
    line-height: 1.3;
    z-index: 1;

    @include breakpoint(large) {
      font-size: rem(20);
      // max-width: 1400px;
      margin-bottom: rem(70);
    }
    @include breakpoint(huge) {
      margin-top: rem(20);
    }

    p {
      margin-bottom: rem(16);
      &:last-child {
        margin-bottom: 0;
      }
    }
    a {
      color: var(--color-primary);
      font-weight: var(--font-medium);
    }
  }

  &_dimensions {
    margin-bottom: rem(60);
    font-size: 3vw;
    font-size: clamp(0.6875rem, 0.269rem + 1.7857vw, 1.125rem);

    @include breakpoint(medium) {
      font-size: clamp(0.6875rem, 0.4792rem + 0.434vw, 1rem);
    }

    @include breakpoint(large) {
      margin-bottom: rem(80);

      &_title {
        max-width: 1336px;
        margin: auto;
        margin-bottom: rem(48);
      }
    }

    &_wrap {
      @include breakpoint(medium) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      &_image {
        position: relative;
        display: flex;
        margin-bottom: rem(30);

        @include breakpoint(medium) {
          width: calc(40% - 12px);
          margin-bottom: 0;
          &:last-child {
            width: calc(60% - 12px);
            margin-left: 24px;
          }
        }

        @include breakpoint(large) {
          width: calc(40% - 32px);
          // max-width: 1000px;
          &:last-child {
            width: calc(60% - 32px);
            margin-left: 64px;
          }
        }

        &_box {
          position: relative;
          flex: 1;
        }
      }

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
        max-width: 100%;
      }
    }
  }

  &_slider {
    padding: rem(16) rem(8);

    @include breakpoint(medium) {
      padding: rem(40) rem(8);
    }

    @include breakpoint(large) {
      padding: rem(80) rem(8);
    }

    &_wrap {
      margin-top: rem(100);
      margin-bottom: rem(56);

      @include breakpoint(large) {
        margin-top: rem(180);
        margin-bottom: rem(140);
      }
    }

    &_small {
      text-align: center;
      @include breakpoint(large) {
        max-width: 1280px;
        margin: auto;
      }
    }
  }

  &_specs {
    margin-bottom: rem(24);
    padding-right: 0;

    @include breakpoint(large) {
      margin-bottom: rem(40);
      padding-left: 0;
    }
  }

  &_yt {
    position: relative;
    margin: rem(64) 0;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    text-align: center;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
    }
  }

  &_form {
    padding-top: rem(40);
    padding-bottom: rem(40);
  }
}
