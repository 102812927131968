@use './styles/_mixins.scss' as *;
.videoScale {
  position: relative;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  height: 100vh;
  z-index: 2;

  @include breakpoint(large) {
    height: 150vh;
  }

  &_shim {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &_sticky {
    position: sticky;
    top: 0;
    width: 100%;
    height: 100%;
    @include breakpoint(large) {
      height: 80%;
    }
  }

  &_video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transform: scale(0.5);
    will-change: transform;
    // filter: brightness(70%);
  }

  &_overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    font-size: rem(32);
    color: white;
    overflow: hidden;
    @include breakpoint(large) {
      font-size: 6rem;
    }
    h2 {
      &:first-child {
        transform: translateX(-100vw);
        color: var(--color-primary);
      }
      &:last-child {
        transform: translateX(100vw);
      }
    }
  }
}
