@use './styles/_mixins.scss' as *;
.comparisonSlider {
  width: 100%;
  position: relative;
  overflow: hidden;
  max-width: 1280px;
  margin: 0 auto;

  &_before {
    position: absolute;
    height: 100%;
    width: 50%;
    top: 0;
    left: 0;
    overflow: hidden;

    img {
      max-width: unset;
    }
  }

  &_after {
    display: block;
  }

  &_resizer {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    left: 50%;
    height: 100%;
    width: 2px;
    background: var(--color-primary);
    /*Stop vertical scrolling on touch*/
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    cursor: ew-resize;

    &_inner {
      position: absolute;
      display: flex;
      align-items: center;
      background: var(--color-primary);
      width: rem(24);
      height: rem(24);
      border-radius: 50%;
      margin-left: rem(-11);
      @include breakpoint(large) {
        width: rem(40);
        height: rem(40);
        margin-left: -19px;
      }

      &:before,
      &:after {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        @include breakpoint(large) {
          border-width: 8px;
        }
      }

      &:before {
        left: 4px;
        border-right: 4px solid var(--color-white);
        @include breakpoint(large) {
          left: 8px;
          border-width: 8px;
        }
      }

      &:after {
        right: 4px;
        border-left: 4px solid var(--color-white);
        @include breakpoint(large) {
          right: 8px;
          border-width: 8px;
        }
      }
    }
  }
}
